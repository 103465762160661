import BaseBean from "@/utils/BaseBean";

export interface IClientLoginDataObj {
    utilInst:ClientLoginUtil
    refMap:Map<string,any>
    fullscreenLoading:boolean
    loginForm:any
}
export default class ClientLoginUtil extends BaseBean{
    public dataObj:IClientLoginDataObj;

    constructor(proxy:any,dataObj:IClientLoginDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    public async login():Promise<any>{
        let res=await this.utils.ToolsProviderApi.clientLogin(this.dataObj.loginForm);
        if(res.code=='0000'){
            sessionStorage.setItem('userInfo',JSON.stringify({code:this.dataObj.loginForm.code,name:res.data}));
            await this.proxy.$router.replace({path:'/clientRepairManual'});
        }else{
            this.proxy.$message(res.message);
        }
    }
}
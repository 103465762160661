import {ref, reactive, toRefs, defineComponent,onBeforeMount,onMounted,getCurrentInstance} from 'vue';
import ClientLoginUtil ,{IClientLoginDataObj} from './clientLoginUtil';
export default defineComponent({
    name:'personCenter',
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IClientLoginDataObj=reactive<IClientLoginDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            fullscreenLoading:false,
            loginForm:{}
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ClientLoginUtil(proxy,dataObj);
        })
        onMounted(()=>{})

        const submitForm=async ()=>{
            if(!dataObj.loginForm.code){
                proxy.$message('请输入用户名');
                return ;
            }
            if(!dataObj.loginForm.pwd){
                proxy.$message('请输入密码');
                return ;
            }
            await dataObj.utilInst.login();
        }
        return{
            ...toRefs(dataObj),submitForm

        }
    }
});